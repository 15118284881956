import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    Output,
    Renderer2,
    Type,
    ViewContainerRef,
} from '@angular/core';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {merge, Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {getScrollParent} from '../../../../utils/dom.utils';
import {NavigationStart, Router} from '@angular/router';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {createComponent} from '../../../../utils/component.utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export const HOVER_DETAILS_DELAY = 500;
export const HOVER_DETAILS_HIDE_DELAY = 200;
export type HoverComponentPosition = 'top-right' | 'middle-right' | 'top-left' | 'middle-left' | 'auto';

@Directive({
    selector: '[hoverComponent]',
})
export class HoverComponentDirective<T = any> extends UnsubscribeComponent {
    @Input('hoverComponent') component: Type<any>;
    @Input() data: T;
    @Input() position: HoverComponentPosition = 'auto';
    @Input() hoverElementExtraStyles: {[property: string]: string};
    @Output('destroyed') destroyed = new EventEmitter<void>();
    @Output('created') created = new EventEmitter<void>();
    componentRef: ComponentRef<any> = null;
    activeModals$ = this.modalService.activeInstances;

    onMouseEnter = () => {
        this.zone.runOutsideAngular(() => {
            if (this._timer) clearTimeout(this._timer);
            this._timer = setTimeout(() => this.zone.run(() => this.createComponent()), HOVER_DETAILS_DELAY);
        });
    };

    onMouseLeave = () => {
        this.zone.runOutsideAngular(() => {
            if (this._timer) clearTimeout(this._timer);
            this._timer = setTimeout(() => this.zone.run(() => this.destroyComponent()), HOVER_DETAILS_HIDE_DELAY);
        });
    };

    _user;
    private _timer;
    private _removeEnterListener;
    private _removeLeaveListener;
    private _intersectionObserver: IntersectionObserver;
    private _destroyComp$ = new Subject<null>();

    constructor(private el: ElementRef,
                private vc: ViewContainerRef,
                private renderer: Renderer2,
                private authService: UserAuthService,
                private zone: NgZone,
                private slidePanelService: SlidePanelService,
                private modalService: NgbModal,
                public router: Router) {
        super();

        this.authService.user.pipe(take(1)).subscribe(user => {
            this._user = user;
            this.destroyComponent();
        });

        this.zone.runOutsideAngular(() => {
            this.el.nativeElement.addEventListener('mouseenter', this.onMouseEnter);
            this.el.nativeElement.addEventListener('mouseleave', this.onMouseLeave);
        });

        merge(
            this.slidePanelService.panelChange,
            this.router.events.pipe(filter(event => event instanceof NavigationStart)),
            this.activeModals$.pipe(filter(x => !!x.length)),
        ).pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this._timer) clearTimeout(this._timer);
            this.destroyComponent();
        });

        this._destroyComp$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.destroyComponent());
    }

    compOnDestroy(): void {
        this.el.nativeElement.removeEventListener('mouseenter', this.onMouseEnter);
        this.el.nativeElement.removeEventListener('mouseleave', this.onMouseLeave);
        this.destroyComponent();
    }

    createComponent(beforeRender?: () => void) {
        if (!this._user || this.componentRef || !this.component) return;

        const scrollParent = getScrollParent(this.el.nativeElement);
        if (!scrollParent) return;

        this.componentRef = createComponent(this.component, this.vc);
        this.componentRef.instance.data = this.data;

        if (beforeRender) beforeRender();

        const previewElement = this.componentRef.location.nativeElement;
        this.renderer.appendChild(document.getElementsByTagName('body')[0], previewElement);

        this._intersectionObserver = new IntersectionObserver(
            intersections => {
                const intRect = intersections[0].intersectionRect;
                const position: {top: number; left: number} = {top: null, left: null};

                if (this.componentRef) {
                    previewElement.style.position = 'absolute';

                    if (this.hoverElementExtraStyles) {
                        Object.assign(previewElement.style, this.hoverElementExtraStyles);
                    }

                    const previewHeight = previewElement.clientHeight;
                    const previewWidth = previewElement.clientWidth;
                    const scrollParentRight = scrollParent.getClientRects()[0].right;
                    const spaceLeftOnRight = scrollParentRight - intRect.right;

                    switch (this.position) {
                        case 'auto':
                            position.top = intRect.top > (window.innerHeight / 2) ?
                                intRect.top - previewHeight + window.scrollY + intRect.height :
                                intRect.top + window.scrollY;
                            position.left = spaceLeftOnRight > previewWidth ?
                                intRect.left + intRect.width :
                                intRect.left + scrollParentRight - (intRect.left + previewWidth);
                            break;
                        case 'top-right':
                            position.top = intRect.top + window.scrollY;
                            position.left = intRect.left + intRect.width;
                            break;
                        case 'middle-right':
                            position.top = intRect.top + (intRect.height / 2) + window.scrollY;
                            position.left = intRect.left + intRect.width;
                            break;
                        case 'top-left':
                            position.top = intRect.top + window.scrollY;
                            position.left = intRect.left;
                            break;
                        case 'middle-left':
                            position.top = intRect.top + (intRect.height / 2) + window.scrollY;
                            position.left = intRect.left;
                            break;
                    }

                    if (position.left < 0) {
                        position.left = 0;
                    } else {
                        const distaceFroViewportRight = window.innerWidth - position.left - previewWidth;
                        if (distaceFroViewportRight < 0) {
                            position.left += distaceFroViewportRight;
                        }
                    }

                    if (position.top || position.left) {
                        previewElement.style.top = `${position.top}px`;
                        previewElement.style.left = `${position.left}px`;
                    } else {
                        this.destroyComponent();
                    }
                }
            },
            {
                root: scrollParent,
            }
        );

        this._intersectionObserver.observe(this.el.nativeElement);

        // We have to bind this so the context stays the same in the rendered component
        this._removeEnterListener = this.renderer.listen(previewElement, 'mouseenter', this.onMouseEnter.bind(this));
        this._removeLeaveListener = this.renderer.listen(previewElement, 'mouseleave', this.onMouseLeave.bind(this));

        this.componentRef.instance.position = this.position;
        this.componentRef.instance.selfDestroy$ = this._destroyComp$;
        this.created.emit();
        this.componentRef.changeDetectorRef.markForCheck();
    }

    destroyComponent() {
        this.destroyed.emit();
        if (!this.componentRef) return;

        if (this._intersectionObserver) {
            this._intersectionObserver.disconnect();
            this._intersectionObserver = null;
        }
        if (this._removeEnterListener) this._removeEnterListener();
        if (this._removeLeaveListener) this._removeLeaveListener();
        this.componentRef.destroy();
        this.componentRef = null;
        this.vc.clear();
        this._destroyComp$.complete();
    }
}
